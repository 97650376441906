<template>
  <div class="index">
    <RiverHeader>
      <template #maxLeft>
        <img
          src="/river-fs/file/view?state=public&clientId=111&fileId=01251219187630003"
          style="width: 50px; height: 30px; margin-left: 10px"
        />
        <span
          style="
            width: 100%;
            height: 100%;
            line-height: 60px;
            padding-left: 10px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: inline-block;
            color: #fff;
            font-size: 25px;
            font-weight: 600;
            text-align: justify;
          "
          >一&nbsp;体&nbsp;化&nbsp;办&nbsp;公&nbsp;平&nbsp;台</span
        >
      </template>
      <template #goback>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" style="color: white" v-if="menuLists.menus && menuLists.menus.length != 0">
            <i class="el-icon-more"></i>
            <!--  下拉菜单 -->
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="goSystem" style="display: inline-block; width: 100%; height: 100%">
                {{ $t('lang_switch_server') }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </RiverHeader>
    <h2>
      <el-button @click="handleCommand(homePage, 0)" :type="id == 0 ? 'primary' : ''" icon="el-icon-s-platform">
        {{ $t('lang_website') }}
      </el-button>
      <el-dropdown  v-for="(item, index) in menuList" :key="index + 1" style="margin: 10px 20px">
        <!-- @command="handleCommand" -->
        <el-button :type="index + 1 == id ? 'primary' : ''" v-if="!item.children" @click="handleCommand(item.route, index + 1)">
          <img class="icon-img" style="height: 1em; width: 1em" :src="`${iconView}?fileId=${item.icon}&token=${token}`" alt="" />
          {{ $t(item.name) }}<i v-show="item.children" class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-button :type="index + 1 == id ? 'primary' : ''" v-else>
          <img class="icon-img" style="height: 1em; width: 1em" :src="`${iconView}?fileId=${item.icon}&token=${token}`" alt="" />
          {{ $t(item.name) }}<i v-show="item.children" class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <!--  -->
        <el-dropdown-menu slot="dropdown" v-show="item.children">
          <el-dropdown-item
            :command="value.route"
            v-for="(value, i) in item.children"
            :key="i + 'item'"
            style="width: 130px; height: 50px; line-height: 50px"
          >
            <span
              style="display: inline-block; width: 100%; height: 100%; line-height: 50px"
              @click="handleCommand(value.route, index + 1, i)"
            >
              <img
                class="icon-img"
                style="height: 1em; width: 1em; margin-right: 10px"
                :src="`${iconView}?fileId=${value.icon}&token=${token}`"
                alt=""
              />
              {{ $t(value.name) }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </h2>
    <section style="height: calc(100% - 140px); overflow-y: auto">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import RiverHeader from '@/views/layout/components/river-header/index.vue';
import { getMenus } from '@/api/main/MainAPi';
import Main from '@/components/oa-header/main.vue';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
export default {
  data() {
    return {
      iconView: '/river-fs/file/view',
      token: '',
      menuList: [],
      user: {},
      loginCompy: {},
      keywords: '',
      companys: [],
      menuLists: JSON.parse(sessionStorage.getItem('menuList')),
      id: sessionStorage.getItem('menuIndex') || 0,
      icon: [
        'el-icon-platform-eleme',
        'el-icon-s-tools',
        'el-icon-user-solid',
        'el-icon-phone',
        'el-icon-s-goods',
        'el-icon-warning',
        'el-icon-star-on'
      ],
      homePage: '',
      ownMenu: [
        {
          name: '公文管理',
          route: '/documentManagement',
          id: 5,
          cateId: '072710111800055'
        },
        { name: '日常工作', route: '/routine', id: 6, cateId: '07270955139110008' }
      ]
    };
  },
  mounted() {
    this.getData();
    // 手动重定向
    this.redirectFn();
    //刷新后 选中顶部菜单
    this.menuSelection();
    this.token = StorageUtil.getSessionStorage('token');
  },
  methods: {
    getData() {
      getMenus({ type: '2' }).then(reg => {
        this.menuList = reg.data.menus;
        this.user = reg.data.user;
        this.loginCompy = reg.data.tenants[0];
        this.companys = reg.data.tenants;
        this.homePage = '/' + reg.data.homePage.split('/')[reg.data.homePage.split('/').length - 1];
      });
    },
    go(route, id, i) {
      this.id = i + 5;
      sessionStorage.setItem('menuIndex', this.id);
      this.$router.push(route + '?cateId=' + id);
    },
    handleCommand(e, index, i) {
      this.id = index;
      sessionStorage.setItem('menuIndex', this.id);
      if (index == 4) {
        this.$router.push({
          path: e,
          query: { cateId: '072710111800055' }
        });
        // window.open(routeUrl.href, "_blank");
      } else if (index == 5) {
        this.$router.push({
          path: e,
          query: { cateId: '07270955139110008' }
        });
        // window.open(routeUrl.href, "_blank");
      } else if (index == 7 && i == 0) this.$router.push(e + '?type=userCanCreateTree');
      else if (index == 7 && i == 1) this.$router.push(e + '?type=userReadTree');
      else this.$router.push(e);
    },
    goSystem() {
      /*console.log(
        window.location.protocol + "//" + window.location.host + "/river/index"
      ); */
      let menuList = JSON.parse(sessionStorage.getItem('menuList'));
      if (menuList.menus && menuList.menus.length != 0) {
        window.open(window.location.protocol + '//' + window.location.host + '/river/home', '_blank');
      } else {
        this.$message.error('您没有此权限！');
      }
    },

    // 重定向
    redirectFn() {
      let menus = JSON.parse(sessionStorage.getItem('menuList'));
      const url = this.$route.path;
      const query = this.$route.query;

      if (menus.homePage && url == '/index' && !query.formId) {
        this.$router.push('/' + menus.homePage.split('/')[menus.homePage.split('/').length - 1]);
      } else if (!menus.homePage) {
        this.$router.push('/index?formId=null');
      }
    },
    // 顶部菜单 刷新后选中
    menuSelection() {
      let id = sessionStorage.getItem('menuIndex');
      this.id = id ? id : 0;
    }
  },
  computed: {},
  watch: {},
  components: { Main, RiverHeader }
};
</script>

<style lang="scss" scoped>
/deep/ .item-time {
  text-align: center;
}
.index {
  height: 100%;
  position: relative;
  .header {
    height: 40px;
    background-color: rgb(2, 112, 193);
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        display: inline-block;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: rgb(31, 140, 247);
      }
      .senice {
        color: white;
        font-size: 10px;
        height: 100%;
        line-height: 40px;
        // width: 70px;
        padding-right: 10px;
        background-color: rgb(31, 140, 247);
      }
      .icon {
        color: white;
        width: 100px;
        i {
          margin: 0 10px;
        }
      }
      .search {
        width: 400px;
        color: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .btn {
          color: white;
          background-color: rgb(2, 103, 178);
          font-size: 10px;
          border-right: 1px solid white;
          padding-right: 5px;
          margin-right: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 70px;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: white;
      i,
      span,
      img {
        margin: 0 5px;
      }
      img {
        border-radius: 50%;
      }
    }
  }
  h2 {
    width: 100%;
    height: 70px;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .el-dropdown {
      margin: 0 0 !important;
    }
    .el-button {
      margin-left: 10px;
    }
    /*     display: flex;
    justify-content: space-around;
    align-items: center; */
  }

  section {
    // height: 460px;
    overflow: auto;
  }
}
</style>
