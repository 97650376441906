var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c("RiverHeader", {
        scopedSlots: _vm._u([
          {
            key: "maxLeft",
            fn: function () {
              return [
                _c("img", {
                  staticStyle: {
                    width: "50px",
                    height: "30px",
                    "margin-left": "10px",
                  },
                  attrs: {
                    src: "/river-fs/file/view?state=public&clientId=111&fileId=01251219187630003",
                  },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "line-height": "60px",
                      "padding-left": "10px",
                      "-webkit-box-sizing": "border-box",
                      "box-sizing": "border-box",
                      display: "inline-block",
                      color: "#fff",
                      "font-size": "25px",
                      "font-weight": "600",
                      "text-align": "justify",
                    },
                  },
                  [_vm._v("一 体 化 办 公 平 台")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "goback",
            fn: function () {
              return [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" } },
                  [
                    _vm.menuLists.menus && _vm.menuLists.menus.length != 0
                      ? _c(
                          "span",
                          {
                            staticClass: "el-dropdown-link",
                            staticStyle: { color: "white" },
                          },
                          [
                            _c("i", { staticClass: "el-icon-more" }),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "100%",
                                height: "100%",
                              },
                              on: { click: _vm.goSystem },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lang_switch_server")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "h2",
        [
          _c(
            "el-button",
            {
              attrs: {
                type: _vm.id == 0 ? "primary" : "",
                icon: "el-icon-s-platform",
              },
              on: {
                click: function ($event) {
                  return _vm.handleCommand(_vm.homePage, 0)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang_website")) + " ")]
          ),
          _vm._l(_vm.menuList, function (item, index) {
            return _c(
              "el-dropdown",
              { key: index + 1, staticStyle: { margin: "10px 20px" } },
              [
                !item.children
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: index + 1 == _vm.id ? "primary" : "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCommand(item.route, index + 1)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-img",
                          staticStyle: { height: "1em", width: "1em" },
                          attrs: {
                            src:
                              _vm.iconView +
                              "?fileId=" +
                              item.icon +
                              "&token=" +
                              _vm.token,
                            alt: "",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t(item.name))),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.children,
                              expression: "item.children",
                            },
                          ],
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    )
                  : _c(
                      "el-button",
                      { attrs: { type: index + 1 == _vm.id ? "primary" : "" } },
                      [
                        _c("img", {
                          staticClass: "icon-img",
                          staticStyle: { height: "1em", width: "1em" },
                          attrs: {
                            src:
                              _vm.iconView +
                              "?fileId=" +
                              item.icon +
                              "&token=" +
                              _vm.token,
                            alt: "",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t(item.name))),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.children,
                              expression: "item.children",
                            },
                          ],
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    ),
                _c(
                  "el-dropdown-menu",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.children,
                        expression: "item.children",
                      },
                    ],
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  _vm._l(item.children, function (value, i) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: i + "item",
                        staticStyle: {
                          width: "130px",
                          height: "50px",
                          "line-height": "50px",
                        },
                        attrs: { command: value.route },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "100%",
                              height: "100%",
                              "line-height": "50px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCommand(
                                  value.route,
                                  index + 1,
                                  i
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "icon-img",
                              staticStyle: {
                                height: "1em",
                                width: "1em",
                                "margin-right": "10px",
                              },
                              attrs: {
                                src:
                                  _vm.iconView +
                                  "?fileId=" +
                                  value.icon +
                                  "&token=" +
                                  _vm.token,
                                alt: "",
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.$t(value.name)) + " "),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "section",
        { staticStyle: { height: "calc(100% - 140px)", "overflow-y": "auto" } },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }